import React, { useState, useEffect } from 'react'
import {
  Container,
  Section,
  CommonBanner,
  Seo,
} from '@components'
import { PageLayout } from '../../components/site/layout/page-layout'
import { OfferForm } from '../../components/site/offer'

const offer = 'trial-signup'

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

const SignupPage = ({ location }) => {
  const [month, setMonth] = useState(null)

  useEffect(() => {
    const now = new Date()
    const m = (now.getMonth() + (now.getDate() > 13 ? 2 : 1)) % 12
    setMonth(months[m])
  }, [setMonth])

  return (
    <PageLayout pathname={location.pathname}>
      <CommonBanner title="Approov Free Trial Sign Up" />

      <Section>
        <Container className="pt-8 container-lg">
          <h2 className="ml-[1rem]">Create an Approov Account</h2>
          <div className="flex flex-col  md:flex-row mb-10">
            <div className="mx-8 md:w-1/2 md:mx-4">
              
              <p className="mt-0">
                Approov offers a complimentary 30 day trial (no credit
                card necessary) to give you immediate and valuable
                insight into the security risks of your mobile apps and the
                devices they run on. It also demonstrates the effectiveness of
                Approov.
              </p>
              <p className="">
                <span className="text-white bg-[#86b146]">
                  For a limited time:
                </span>
                &nbsp;Sign up before {month ? `${month} 15` : `the 15th`}, and
                we will schedule a free security review of your mobile apps,
                back-end APIs, communications channels, and API key management.
              </p>
              <p className="">
                With a trial, when you deploy your app with Approov, you can
                find out:
              </p>
              <ul>
                <li>
                  If <strong>unauthorized software</strong> is being used against your business,
                  e.g bots, scripts, or repackaged or cloned apps
                </li>
                <li>
                  If your communications are being intercepted by <strong>MitM</strong> or
                  "Man-in-the-Device" attacks
                </li>
                <li>
                  If your apps are running on mobile devices that are
                  <strong> rooted/jailbroken</strong> or if emulators, debuggers or other hacking
                  frameworks are attached to your apps
                </li>
                <li>
                  How to <strong>dynamically manage API secrets</strong> and certificates to keep
                  them safe from hackers—while simplifying DevOps processes
                </li>
              </ul>

            </div>
            <div className="mx-8 md:w-1/2 md:mx-4">
              <OfferForm
                className="px-4 bg-gray-100 rounded-lg"
                offer={offer}
              />
            </div>
          </div>
        </Container>
      </Section>
    </PageLayout>
  )
}

export default SignupPage